import React from 'react'



const Contacts = (props) => {

    return(
        <div className="contacts">
            <div className="contact-area">
                <h2>Contacts</h2>
            </div>

        </div>
    )
}

export default Contacts